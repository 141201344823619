import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'
import MainNav from 'components/functional/mainNav'

const LegalWrapperStyles = styled.div`
  width: 100%;
  background: var(--legalBackground);
  @media ${media.slg} {
    min-height: calc(100vh - 165px);
  }
  .l-b {
    width: 85%;
    margin: var(--auto);
    max-width: 900px;
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
      font-weight: 800;
      @media ${media.sm} {
        font-size: 1.85rem;
      }
    }
    a {
      color: var(--cyan);
      font-weight: 900;
    }
    p {
      margin-bottom: 0.5rem;
    }
    ul {
      margin-left: 1.15rem;
    }
    section {
      margin-bottom: 1rem;
    }
    @media ${media.sm} {
      padding-top: 1.5rem;
    }
    @media ${media.md} {
      padding-top: 1.5rem;
      min-height: calc(80vh - 400px);
    }
  }
`
const LegalWrapper = ({ title, children }) => (
  <LegalWrapperStyles>
    <MainNav alt />
    <LegalHeader title={title} />
    <div className="l-b">{children}</div>
  </LegalWrapperStyles>
)

export default LegalWrapper

const LegalHeaderStyles = styled.header`
  width: 100%;
  padding-top: 8rem;
  background: var(--legalBackground);
  @media ${media.sm} {
    padding-top: 2rem;
  }
  p {
    strong {
      font-weight: 900;
      font-family: var(--PoppinsBlack);
    }
  }
  .l-h {
    h1 {
      text-align: center;
      font-size: 2.5rem;
      margin: var(--auto);
      font-weight: 900;
      width: 85%;
    }
    &--box {
      background-color: var(--white);
      width: 100%;
      padding: 1.5rem 0;
      overflow-x: auto;
      ul {
        width: 100%;
        max-width: 35rem;
        margin: auto;
        list-style: none;
        display: flex;
        justify-content: flex-start;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        background: #ffffff;
        box-shadow: 0 0 3.3333rem 0 #e5e9f0;
        border: 1px solid #e5e9f0;
        border-radius: 4px;
        li a {
          display: block;
          padding: 1.1111rem 2.2em;
          white-space: nowrap;
          width: auto;
          transition: color 0.2s ease-in-out;
          cursor: pointer;
          font-size: 15px;
          font-size: 0.8333rem;
          &[aria-current='page'] {
            border-bottom: var(--cyan) 2px solid;
          }
          &:hover,
          &:focus {
            border-bottom: var(--cyan) 2px solid;
          }
        }
      }
    }
    @media ${media.sm} {
      h1 {
        padding: 3rem 0;
      }
      &--box {
        ul {
          justify-content: center;
        }
      }
    }
  }
`
const LegalHeader = ({ title }) => {
  const data = useStaticQuery(graphql`
    query LegalHeaderQuery {
      Links: allLegalLinksJson {
        nodes {
          title
          link
        }
      }
    }
  `)
  const links = data.Links.nodes
  console.log(links)
  return (
    <LegalHeaderStyles>
      <div className="l-h">
        <h1>{title}</h1>
        {/* <div className="l-h--box">
          <ul>
            {links.map((link, i) => (
              <li key={i}>
                <Link to={`${link.link}/`}>{link.title}</Link>
              </li>
            ))}
          </ul>
        </div> */}
      </div>
    </LegalHeaderStyles>
  )
}
